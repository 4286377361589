import React from "react";
import { graphql } from "gatsby";
import { patchComposePageLink } from "../utils/articles";

import Page from "../components/global/Page/page";

export const query = graphql`
  query staticPageQuery($id: String!) {
    contentfulComposePage(id: {eq: $id}) {
      ...ComposePageFragment
    }
    ...ArticleFragment
  }
`

const StaticPage = ({ data, location }) => (
  <Page data={data.contentfulComposePage} articleData={patchComposePageLink(data).allContentfulCpsEeArticle.nodes} state={ {...location.state} }/>
)

export default StaticPage;
